import { captureException } from '@sentry/browser';
import { ofetch } from 'ofetch';

/**
 * Logout class
 * @param {string} url - The url to logout
 * @param {string} method - The method to use
 * @param {Record<string, unknown>} data - The data to send
 *
 * @see https://inertiajs.com/links
 */
class Logout {
  private readonly url: string;
  private readonly method: string;
  private readonly data: Record<string, unknown>;

  constructor(url: string, method: string, data: Record<string, unknown> = {}) {
    this.url = url;
    this.method = method;
    this.data = data;
  }

  public async logout() {
    try {
      const resp = await ofetch<{
        location?: string | null;
      }>(this.url, {
        method: this.method,
        headers: {
          'Content-Type': 'application/json',
          ...useCSRFToken().getCSRFHeader(),
        },
        body: this.data,
      });
      if (resp.location) {
        window.location.href = resp.location;
      }
    } catch (err) {
      console.error('Failed to logout', err);
      captureException(err, {
        extra: {
          url: this.url,
          method: this.method,
          data: this.data,
        },
      });
    }
  }
}

const LogoutButton = {
  capture() {
    for (const btn of document.querySelectorAll<HTMLButtonElement>('button[data-logout-button]')) {
      const url = btn.dataset.href || '';
      const method = btn.dataset.method || 'GET';

      const logout = new Logout(url, method);
      btn.addEventListener('click', () => {
        logout.logout();
      });
    }
  },
};

export default LogoutButton;
