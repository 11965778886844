import {
  browserProfilingIntegration,
  browserTracingIntegration,
  captureConsoleIntegration,
  feedbackIntegration,
  init,
  replayIntegration,
} from '@sentry/browser';
import ScrollHint from 'scroll-hint';
import { Tooltip } from 'tw-elements';
import 'vite/modulepreload-polyfill';

import LogoutButton from '../utils/logout_button';

import '../styles/index.css';

if (import.meta.env.VITE_SENTRY_DSN) {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: "my-project-name@2.3.12",
    integrations: [
      captureConsoleIntegration(),
      browserTracingIntegration(),
      browserProfilingIntegration(),
      replayIntegration(),
      feedbackIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
    tracesSampleRate: 0.5, // TODO: 設定変更できるようにする

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [location.hostname],

    // Profiling
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.5,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

onReady(() => {
  // Scroll hint
  new ScrollHint('[data-scroll-hint]', {
    suggestiveShadow: true,
    i18n: {
      scrollable: 'スクロールできます',
    },
  });

  // Tooltip
  for (const el of document.querySelectorAll('[data-te-toggle="tooltip"]')) {
    new Tooltip(el);
  }

  // Logout button
  LogoutButton.capture();
});
